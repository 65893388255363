import { useEffect } from 'react';

import { signIn } from 'next-auth/react';

import { useRouter, NextPage } from '@dxp-next';

export const LoginPage: NextPage = () => {
  const { basePath, isReady, query } = useRouter();

  useEffect(() => {
    if (!isReady) return;

    const callbackUrl = (query?.returnUrl as string) ?? basePath;
    signIn('okta', { callbackUrl: callbackUrl });
  }, [basePath, isReady, query]);

  return null;
};
